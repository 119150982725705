import { TimeExceedAction } from '../../modules/external-chat-outlet/components/modal/e-chal-modal';
import { Participant } from '../../modules/root/new-queue/modules/call/modals/call-config';

export interface media {
  content_type: string;
  filename: null;
  sid: string;
  size: number;
}

export interface ChatMessage {
  accountSid?: string;
  attributes: MessageAttribute;
  body: string;
  conversationSid?: string;
  dateCreated?: string;
  dateUpdated?: string;
  author: string;
  index?: number;
  lastUpdatedBy?: string;
  media?: any;
  participantSid?: string;
  sid?: string;
  url?: string;
  updatedTime?: string;
  showMessage?: boolean;
  participantName?: string;
  showCustomOptions?: boolean;
}

export interface ConversationWebHookMessage {
  AccountSid: string;
  Attributes: MessageAttribute;
  Author: string;
  Body: string;
  ClientIdentity?: string;
  ChatServiceSid: string;
  ConversationSid: string;
  DateCreated: string;
  EventType: string;
  Index: string;
  MessageSid: string;
  RetryCount: string;
  ParticipantSid?: string;
  MessagingServiceSid: string;
  RoleSid?: string;
  Source: string;
  showMessage?: boolean;
  participantName?: string;
  showCustomOptions?: boolean;
  Media?: any;
}

export interface Member {
  channel_sid: string;
  date_updated: string;
  last_consumption_timestamp: string;
  account_sid: string;
  last_consumed_message_index: string;
  role_sid: string;
  sid: string;
  date_created: string;
  service_sid: string;
  identity: string;
  attributes: any;
  first_name: string;
  last_name: string;
}

export interface ChannelCreateReq {
  channel_name: string;
  type?: string;
  created_by: number;
}

export interface ChannelCreateRes {
  status: number;
  data: NewChannelData;
}

export interface NewChannelData {
  unique_name: string;
  members_count: number;
  date_updated: string;
  friendly_name: string;
  created_by: string;
  account_sid: string;
  date_created: string;
  sid: string;
  attributes: null;
  service_sid: string;
  type: string;
  messages_count: number;
}

export interface NewMemberData {
  channel_sid: string;
  date_updated: string;
  last_consumption_timestamp: string;
  account_sid: string;
  last_consumed_message_index: string;
  role_sid: string;
  sid: string;
  date_created: string;
  service_sid: string;
  identity: string;
  attributes: null;
}

export enum EventTypes {
  messageUpdated = 'onMessageUpdated',
  messageAdded = 'onMessageAdded'
}

export interface SendReq {
  channelSid: string;
  message: string;
  identity: string;
  attributes?: MessageAttribute;
}

export interface MessageUpdateReq {
  channelSid: string;
  message: string;
  identity: string;
  chatServiceSid: string;
  attributes?: MessageAttribute;
}

export interface MessageAttribute {
  index?: number;
  private?: boolean;
  visible_to?: string[];
  custom_message?: CustomMessage;
  status?: MessageStatus;
  messageCode?: string;
  message_type?: string;
  user_type?: string;
  user_id?: number;
  user_name?: string;
  action?: string;
  fileType?: any;
  fileStatus?: string;
  translation?: TranslationOBj;
  stream_uuid?: string;
  format?: string;
  recommendation?: RecommendationAttr;
  vehicles?: VehicleList[];
}

export interface VehicleList {
  image: string;
  year:number;
  make: string;
  model: string;
  trim: string;
  vin:string;
  price:string;
  status:string;
  color:string;
  fuel_type: string;
  vehicle_url: string;
  mileage?: string
  features: string[];
}

export interface RecommendationAttr {
  message: string[];
  used: number;
  modified: number;
  category?: string;
  subassistant?: string;
  delay?: number;
}

export enum MessageType {
  joined = 'joined',
  left = 'left'
}

export enum MsgUserType {
  agent = 'agent',
  guest = 'guest'
}

export enum MessageStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  FAILED = 'failed'
}

export interface CustomMessage {
  event_key?: string;
  type: string;
  response_from: string;
  response_options: CustomButtons[];
  response_value: string;
}

export interface CustomButtons {
  text: string;
  type: string;
  action?: TimeExceedAction;
  skip_to_question?: number;
  queue_id?: number;
  skip_to_question_fail?: number;
  nextQuestionDetails?: any;
  url?: string;
}

export interface ChatResponse {
  message?: ChatMessage[];
  nextPageUrl?: string;
  nextPageToken?: string;
  instances?: ChatMessage[];
}

export interface Message {
  accountSid?: string;
  attributes?: MessageAttribute;
  body?: string;
  conversationSid?: string;
  dateCreated?: string;
  dateUpdated?: string;
  author?: string;
  index?: number;
  lastUpdatedBy?: string;
  media?: MediaObj[] | any;
  participantSid?: string;
  sid?: string;
  url?: string;
  updatedTime?: string;
  showMessage?: boolean;
  participantName?: string;
  dateInFormat?: string;
  dateDisplay?: string;
  tempMediaUrl?: string;
}

export interface MediaObj {
  category?: string;
  content_type?: string;
  size?: number;
  filename?: string;
  sid?: string;
}

export enum InviteReadStatus {
  adHoc = 'Ad Hoc',
  outBound = 'Outbound',
  inProgress = 'In Progress',
  pending = 'Pending',
  missed = 'Missed',
  cancelled = 'Cancelled',
  scheduled = 'Scheduled',
  closed = 'Closed',
  routing = 'Routing',
}

export interface TypingInfo {
  isTyping: boolean;
  channelId: string;
  identity: string;
}

export const cobrowseMessage: string =
  '<h4>{{agent}} invites you to co-browse!</h4><p>Collaborative browsing (co-browse) allows {{agent}} to navigate the site with you at the same time. <br/>Do you allow {{agent}} to take control of your screen?</p>';

export interface MediaUrlUpdate {
  url: string;
  sid: string;
}

export const FileTypes = [
  {
    name: 'image',
    svgName: 'file-image'
  },
  {
    name: 'audio',
    svgName: 'file-music'
  },
  {
    name: 'video',
    svgName: 'file-video'
  },
  {
    name: 'pdf',
    svgName: 'file-pdf'
  },
  {
    name: 'spreadsheetml',
    svgName: 'file-excel'
  },
  {
    name: 'word',
    svgName: 'file-word'
  },
  {
    name: 'powerpoint',
    svgName: 'file-powerpoint'
  },
  {
    name: 'zip',
    svgName: 'file-zipper'
  }
];

export interface TranslateTextRes {
  languages: string;
  detLang: string;
  updatedMessage?: ChatMessage;
}

export interface TranslateSub {
  roomName: string;
}

export interface TranslationOBj {
  original_language: string;
  translated_text?: TranslatedTxt;
  original_text?: string;
  translated_language?: string;
}

export interface TranslatedTxt {
  [key: string]: string;
}

export interface TranslateReq {
  text: string;
  target: string;
  update: boolean;
  roomName?: string;
  identity?: string;
  chatServiceId?: string;
  attribute?: MessageAttribute;
  from?: string;
}

export interface MemberUpdateRes {
  updateMemberRes: Participant[];
  getMemberRes: Participant[];
}

export interface UpdateDeleteMemberRes {
  memberRemoved: boolean;
  getMemberRes: Participant[];
}
